import React, { useEffect } from 'react';
import { graphql } from 'gatsby';

import WrapPbSection from 'components/common/WrapPbSection';
import WrapSection from 'components/common/WrapSection';

import { IFrameStyled } from './PageBuilderIFrameStyled';

export default function PageBuilderIFrame({ data }) {
  const {
    iFrameUrl
  } = data;
  
  const gotResizeMessage = (event) => {
    if ( event.origin == 'https://www2.warsteiner.us' ) {
        console.log(event.origin, event.data.height, event.data.href)
        if (document.querySelector( 'iframe[src="' + event.data.href + '"]' )) {
            document.querySelector( 'iframe[src="' + event.data.href + '"]' ).style.height = event.data.height + 10 + 'px';
        }
    }
  }

  useEffect(() => {
    console.log('test in component');
    window.addEventListener('message', gotResizeMessage, false);
  }, []);

  return (
    <WrapPbSection>
      <WrapSection posed>
        <IFrameStyled src={iFrameUrl} />
      </WrapSection>
    </WrapPbSection>
  );
}

export const query = graphql`
  fragment PageBuilderIFrameQuery on Craft_PageBuilderNeoIFrame {
    id
    iFrameUrl
  }
`;