import styled from 'utils/emotion';

import media from 'utils/mediaqueries';

export const IFrameStyled = styled('iframe')({
  width: '100%',
  border: '0',

  [media('tabletFluid')]: {
  },
});
